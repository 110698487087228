import { css } from "@emotion/core";
import { scrollbar } from "@styles/Scrollbar";
import { styled } from "@styles/theme";
import { Flex } from "../ResponsiveBox";

interface InputStyleProps {
  focus?: boolean;
  error?: boolean;
  success?: boolean;
  icon?: "left" | "right";
  disabled?: boolean;
  small?: boolean;
  shadow?: boolean;
}

export const OptionButton = styled.button`
  min-height: ${(p) => p.theme.spacing.xxxg};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Wrapper = styled.div<InputStyleProps>`
  width: 100%;

  & label {
    display: block;
    line-height: 19px;
    margin-bottom: ${(p) => p.theme.spacing.xs};
  }

  & .label_popover {
    margin-bottom: ${(p) => p.theme.spacing.xs};

    label {
      margin: 0 ${(p) => p.theme.spacing.xxs} 0 0;
    }
  }

  & .label_popover_inside {
    position: absolute;
    right: 16px;
    bottom: 10px;

    button {
      height: 24px;
    }
  }

  & .input__container {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: ${(p) => (p.icon === "right" ? "row-reverse" : "row")};

    width: 100%;

    background: ${(p) =>
      p.disabled ? p.theme.colors.neutral[300] : p.theme.colors.neutral[0]};
    border-radius: ${(p) => p.theme.radius};
    box-shadow: ${(p) => p.shadow && p.theme.shadow.xs};
    border: 1px solid;
    border-color: ${(p) =>
      p.error
        ? p.theme.colors.red["400"]
        : p.success
        ? p.theme.colors.green[400]
        : p.focus
        ? p.theme.colors.blue[400]
        : p.theme.colors.neutral[400]};
  }

  & .input__icon {
    background: none;
    border: none;
    margin-right: ${(p) => (p.icon === "right" ? p.theme.spacing.md : 0)};
    margin-left: ${(p) => (p.icon === "left" ? p.theme.spacing.md : 0)};

    :focus {
      box-shadow: ${(p) => p.theme.shadow.focus};
    }
  }
  & input:-webkit-autofill {
    font-family: ${(p) => p.theme.fonts.main};
    font-size: 14px;
    line-height: 19px;
    -webkit-text-fill-color: ${(p) => p.theme.colors.primary["500"]} !important;
  }

  & input {
    width: 100%;
    height: ${(p) => (p.small ? "27px" : "100%")};
    min-height: 19px;

    font-family: ${(p) => p.theme.fonts.main};
    font-size: 14px;
    line-height: 19px;
    margin-left: 0;
    color: ${(p) =>
      p.disabled
        ? p.theme.colors.primary["200"]
        : p.theme.colors.primary["500"]};
    padding: ${({ theme, small }) =>
      `${small ? theme.spacing.xxs : theme.spacing.sm} ${theme.spacing.md}`};

    border: none;
    background: none;
  }

  & input[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      appearance: none;
    }
  }

  & .input_number_arrows__container {
    position: absolute;
    top: 0;
    right: 0;
    width: 18px;
    height: 100%;

    div {
      display: flex;
      align-items: flex-end;
      width: 100%;
      flex: 1;
      margin: 2px 0;
      cursor: pointer;

      &:last-of-type {
        align-items: flex-start;
      }
    }
  }

  & > .input__helper-text {
    font-size: ${(p) => p.theme.spacing.sm};
    color: ${(p) => p.theme.colors.blue["600"]};
    margin-top: ${(p) => p.theme.spacing.xs};
  }

  & > .input__error-text {
    font-size: ${(p) => p.theme.spacing.sm};
    text-align: left;
    margin-top: ${(p) => p.theme.spacing.xs};
    color: ${(p) => p.theme.colors.red["700"]};
    width: 150%;
  }

  & > .input__helper-text::first-letter,
  & > .input__error-text::first-letter {
    text-transform: capitalize;
  }
`;

export const FormWrapper = styled.form`
  width: 100%;
  position: relative;

  & .form__input + .form__input {
    margin-top: ${(p) => p.theme.spacing.lg};
  }
`;

interface SelectButtonProps {
  small: boolean;
  focus: boolean;
  error?: boolean;
}

export const SelectButton = styled.button<SelectButtonProps>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
  ${(p) =>
    p.small &&
    css`
      border: 1px solid;
      border-color: ${p.error
        ? p.theme.colors.red["500"]
        : p.focus
        ? p.theme.colors.blue["400"]
        : p.theme.colors.neutral["300"]};
      border-radius: ${p.theme.radius};
      box-shadow: ${p.theme.shadow.xs};

      .input__container {
        border: none !important;
      }
    `};

  svg {
    display: block;
  }
`;

export const SelectButtonLink = styled(Flex)`
  position: relative;
  border: none;
  cursor: pointer;

  .input__container {
    border: none !important;
  }

  & + div {
    width: auto;

    button {
      white-space: nowrap;
    }
  }
`;

export const ErrorBox = styled.div`
  font-size: ${(p) => p.theme.spacing.sm};
  text-align: left;
  color: ${(p) => p.theme.colors.red[700]};
  margin-top: ${(p) => p.theme.spacing.xs};

  ::first-letter {
    text-transform: uppercase;
  }
`;

export const Options = styled(Flex)`
  ${scrollbar};
  background: white;
  z-index: 1;
  box-shadow: ${(p) => p.theme.shadow.xs};
  overflow-y: auto !important;
  border-radius: ${(p) => p.theme.radius};

  span,
  button {
    font-size: 14px;
    width: 100%;
    color: ${(p) => p.theme.colors.primary[500]};
    padding: ${(p) => `${p.theme.spacing.sm} ${p.theme.spacing.xxxg}`};
    text-align: left;
    font-family: ${(p) => p.theme.fonts.main};
    font-weight: 500;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
    position: relative;

    div {
      left: 0;
      top: 0;
    }

    :hover {
      background: ${(p) => p.theme.colors.neutral[200]};
    }
  }
`;

export const TagsWrapper = styled(Flex)`
  ${scrollbar};
  overflow-y: auto;
  z-index: 1;
`;

export const MailTag = styled(Flex)`
  border-radius: ${(p) => p.theme.radius};

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 263px;
  }
`;

export const RemoveButton = styled.button<{ disabled?: boolean }>`
  width: 16px;
  height: 16px;
  cursor: ${(p) => (p.disabled ? "default" : "pointer")};
  background: none;
  border: none;
  position: absolute;
  right: ${(p) => p.theme.spacing.xxs};

  :focus {
    box-shadow: ${(p) => p.theme.shadow.focus};
  }
`;

export const MailTagsWrapper = styled.div<{ hasTags: boolean }>`
  position: relative;
  width: 100%;
`;

export const Checkbox = styled.div<{ checked: boolean }>`
  width: 15px;
  height: 15px;
  border-radius: ${(p) => p.theme.radius};
  border: 2px solid
    ${(p) =>
      p.checked ? p.theme.colors.blue[500] : p.theme.colors.primary[100]};
  background: ${(p) => (p.checked ? p.theme.colors.blue[500] : "transparent")};
  display: flex;
  align-items: center;
`;
