import React, { PropsWithChildren, useEffect } from "react";

import { FeedbackContext } from "@contexts/FeedbackContext";

import { styled, Theme } from "../../styles/theme";
import { dynamicMargin, MarginProps } from "../../styles/helpers";

import Icon, { IconName } from "../../icons/Icon";

import { Flex, FlexProps } from "./ResponsiveBox";
import { Spacer } from "./Spacer";
import { Text } from "./Text";
import Link from "./Link";

export type Variant = Exclude<
  keyof Theme["colors"],
  "white" | "orange" | "primary" | "colorBackground"
>;

const DEFAULT_COLOR = "blue" as const;
export type Props = MarginProps & {
  id?: string;
  color: Variant;
  icon?: IconName;
  onClose?: () => void;
  actions?: {
    label: string;
    href?: string;
    onClick?: () => void;
    separator?: string;
  }[];
  duration?: number;
  style?: object;
};

const MessageContainer = styled(Flex)<Props & FlexProps>`
  ${dynamicMargin};

  padding: ${(p) => p.theme.spacing.md} ${(p) => p.theme.spacing.lg};
  background-color: ${(p) =>
    p.theme.colors[p.color ? p.color : DEFAULT_COLOR][100]};
  width: 100%;
  font-size: 14px;
  color: ${(p) => p.theme.colors[p.color ? p.color : DEFAULT_COLOR][700]};
  box-shadow: ${(p) => p.theme.shadow.xs};
  border-radius: 3px;
  border-top: 3px solid;
  border-color: ${(p) =>
    p.theme.colors[p.color ? p.color : DEFAULT_COLOR][700]};

  > .iconMessage svg {
    min-width: 24px;
  }
`;

const MessageText = styled(Text)`
  flex-grow: 1;
`;

const MessageIcon = styled.div`
  cursor: pointer;
  margin-left: ${(p) => p.theme.spacing.lg};

  svg {
    display: block;
  }
`;

const CustomAction = styled(Link)`
  line-height: normal;
  text-align: center;
`;

export const Message: React.FC<PropsWithChildren<Props>> = ({
  children,
  icon,
  onClose,
  actions,
  style,
  ...props
}) => {
  const { removeFeedback } = React.useContext(FeedbackContext);

  useEffect(() => {
    if (props.id && props.duration) {
      const timeout = setTimeout(() => {
        removeFeedback(props.id);
      }, props.duration);

      return () => clearTimeout(timeout);
    }
  }, [props.id]);

  return (
    <MessageContainer
      {...props}
      direction={actions && actions.length ? ["column", "row"] : "row"}
      alignItems="center"
      justify="space-between"
      style={style}
    >
      <Flex
        className="iconMessage"
        direction="row"
        alignItems="center"
        mB={["md", "none"]}
      >
        {icon && (
          <>
            <Icon name={icon} size={24} fill={`${props.color}.700`} />
            <Spacer horizontal size="md" />
          </>
        )}

        <MessageText color={props.color} colorWeight="700">
          {children}
        </MessageText>
      </Flex>

      <Flex direction="row" alignItems="center" pL="md">
        {actions &&
          actions.map((action) => (
            <Flex
              direction="row"
              alignItems={action.separator ? "center" : "baseline"}
            >
              <CustomAction
                href={action?.href || "javascript:void(0)"}
                onClick={action.onClick}
                color={props.color}
                colorWeight="700"
                weight={700}
                target={action.href && "_blank"}
              >
                {action.label}
              </CustomAction>

              {action.separator && (
                <Text color={props.color} colorWeight="700" mX="sm">
                  {action.separator}
                </Text>
              )}
            </Flex>
          ))}

        {onClose && (
          <MessageIcon onClick={() => onClose()}>
            <Icon name="close" size={24} fill={`${props.color}.500`} />
          </MessageIcon>
        )}
      </Flex>
    </MessageContainer>
  );
};
