import React from "react";

import { Props } from "@components/common/Message";

export type MessageType = Props & {
  id: string;
  message: string | JSX.Element;
};

export interface Feedback {
  feedback: MessageType[];
  setFeedback: (feedback: Omit<MessageType, "id"> & { id?: string }) => string;
  removeFeedback: (id?: string) => void;
}

export const FeedbackContext = React.createContext<Feedback>({} as Feedback);
