import React, {
  useState,
  PropsWithChildren,
  PropsWithRef,
  CSSProperties,
} from "react";
import invariant from "ts-invariant";

import { styled } from "@styles/theme";
import { breakpoints } from "@styles/responsive";

import { Wrapper, FormWrapper } from "./styles";

import Icon, { IconName } from "../../../icons/Icon";

import { Text } from "../Text";
import { Message } from "../Message";
import { Flex } from "../ResponsiveBox";
import { Spinner } from "../Spinner";

export { useForm } from "@hooks/useForm";

type IconProps = {
  name: IconName;
  width?: number;
  height?: number;
  fill?: string;
};

export type Props = PropsWithChildren<{
  id?: string;
  value?: string | null;
  onChange?: (v: string) => void;
  label?: string;
  ariaLabel?: string;
  required?: boolean;
  type?: "text" | "email" | "password" | "number" | "date";
  errors?: string[];
  placeholder?: string;
  icon?: IconName | IconProps;
  iconRight?: boolean;
  style?: CSSProperties;
  error?: boolean;
  success?: boolean;
  disableAutoComplete?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  autoFocus?: boolean;
  disabled?: boolean;
  iconAction?: () => void;
  readOnly?: boolean;
  hidden?: boolean;
  ariaHidden?: boolean;
  tabIndex?: number;
  dataTestId?: string;
  number?: {
    min?: number;
    max?: number;
  };
  minValue?: string;
  maxValue?: string;
  pattern?: string;
  small?: boolean;
  shadow?: boolean;
  loading?: boolean;
}>;

interface FormProps {
  errors?: string[];
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  dataTestId?: string;
  id?: string;
}

export const Form: React.FC<FormProps> & { Actions: React.ElementType } = ({
  errors,
  onSubmit,
  children,
  dataTestId,
  id,
}) => {
  function handleSubmit(e: React.SyntheticEvent<HTMLFormElement>) {
    e.preventDefault();
    onSubmit?.(e);
  }

  return (
    <FormWrapper id={id} data-testid={dataTestId} onSubmit={handleSubmit}>
      {errors && Boolean(errors.length) && (
        <Message color="red" mB="xxxg">
          {errors[0]}
        </Message>
      )}
      {children}
    </FormWrapper>
  );
};

Form.Actions = styled.div`
  margin-top: ${(p) => p.theme.spacing.xxg};
`;

function getIconProps(icon?: IconName | IconProps) {
  if (!icon) return null;

  if (typeof icon === "string") {
    return { name: icon, width: 20, height: 20 };
  }

  return {
    name: icon.name,
    width: icon.width || 20,
    height: icon.height || 20,
    fill: icon.fill,
  };
}

const _Input: React.FC<PropsWithRef<Props>> = (
  {
    label,
    ariaLabel,
    type = "_text",
    required,
    value,
    icon = undefined,
    iconRight = false,
    iconAction,
    errors = [],
    onChange = () => null,
    children,
    placeholder,
    style,
    error,
    disableAutoComplete = false,
    onFocus,
    onBlur,
    autoFocus = false,
    disabled,
    readOnly,
    hidden,
    ariaHidden,
    number,
    success,
    tabIndex,
    dataTestId,
    minValue,
    maxValue,
    pattern,
    small,
    shadow,
    loading,
  },
  ref: React.Ref<HTMLInputElement>,
) => {
  const handleAriaLabel = () => {
    if (label || ariaLabel || hidden || ariaHidden) return true;
    if (!label && !ariaLabel && !hidden && !ariaHidden) return false;
  };

  invariant(handleAriaLabel(), "Must provide aria-label or label");
  invariant(
    readOnly || onChange,
    "If input is not readOnly an onChange must be provided",
  );
  const [isTyping, setIsTyping] = useState(false);
  const receivedFocusRef = React.useRef(false);
  const [focus, setFocus] = useState(false);
  const id = `input.${label || ariaLabel}.${type}`;

  React.useEffect(() => {
    if (!focus) setIsTyping(false);
  }, [focus]);

  const showErrors =
    (errors?.filter(Boolean).length > 0 && (focus === false || !isTyping)) ||
    error;

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setIsTyping(true);
    onChange(e.currentTarget.value);
  };

  const iconPosition = icon ? (iconRight ? "right" : "left") : undefined;
  const urlCheck = ariaLabel === "url" && !focus && errors[0] === null;

  const iconProps = getIconProps(icon);

  const handleOnFocus = (): void => {
    receivedFocusRef.current = true;
    setFocus(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleOnBlur = (): void => {
    setFocus(false);
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <Wrapper
      focus={focus}
      error={showErrors}
      success={success}
      icon={iconPosition}
      disabled={disabled}
      small={small}
      shadow={shadow}
      className="form__input"
    >
      {label && (!hidden || !ariaHidden) && (
        <label htmlFor={id}>
          <Text weight={600}>{label}</Text>
        </label>
      )}
      <div
        style={{
          ...style,
          borderLeft: urlCheck ? "none" : undefined,
          borderRight: urlCheck ? "none" : undefined,
        }}
        className="input__container"
      >
        {(icon || iconProps?.name) && (
          <div
            className="input__icon"
            onClick={iconAction}
            style={{
              cursor: iconAction ? "pointer" : "default",
              display: "flex",
            }}
            role="button"
          >
            {loading ? (
              <Spinner
                size={16}
                mL="sm"
                mR={iconPosition !== "right" ? "lg" : "none"}
              />
            ) : (
              <Icon
                width={iconProps?.width}
                height={iconProps?.height}
                name={(iconProps?.name || icon) as IconName}
                fill={iconProps?.fill || "neutral.500"}
              />
            )}
          </div>
        )}
        <input
          id={id}
          ref={ref}
          type={type}
          aria-label={label}
          aria-required={required}
          value={value || ""}
          onChange={handleChange}
          placeholder={placeholder}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          autoFocus={autoFocus}
          autoComplete={disableAutoComplete ? "off" : "on"}
          disabled={disabled}
          readOnly={readOnly}
          hidden={hidden}
          aria-hidden={ariaHidden}
          tabIndex={tabIndex}
          data-testid={dataTestId}
          min={minValue}
          max={maxValue}
          pattern={pattern}
        />
        {type === "number" && (
          <Flex
            height={43}
            justify="center"
            className="input_number_arrows__container"
          >
            <div
              onClick={() => {
                if (!disabled) {
                  number?.max
                    ? Number(value) < number.max &&
                      onChange(`${Number(value) + 1}`)
                    : onChange(`${Number(value) + 1}`);
                }
              }}
            >
              <Icon
                name="chevron-up"
                width={10}
                height={10}
                fill={
                  Number(value) == number?.max ? "neutral.400" : "primary.500"
                }
              />
            </div>
            <div
              onClick={() => {
                if (
                  (number?.min || number?.min === 0) &&
                  Number(value) > number?.min &&
                  !disabled
                ) {
                  onChange(`${Number(value) - 1}`);
                }
              }}
            >
              <Icon
                name="chevron-down"
                width={10}
                height={10}
                fill={
                  (number?.min || number?.min === 0) &&
                  Number(value) <= number?.min
                    ? "neutral.400"
                    : "primary.500"
                }
              />
            </div>
          </Flex>
        )}
      </div>
      {showErrors && !!errors?.length && (
        <div className="input__error-text">{errors[0]}.</div>
      )}
      {children && (
        <Text
          paragraph
          align="left"
          className="input__helper-text"
          color="blue"
          colorWeight={600}
          size={12}
          weight={600}
        >
          {children}
        </Text>
      )}
    </Wrapper>
  );
};

export const Input = React.forwardRef<HTMLInputElement, Props>(_Input);

type PropsInputURL = PropsWithChildren<{
  label?: string;
  errors?: string[];
  style?: CSSProperties;
  url?: {
    value?: string | null;
    errors?: string[];
    onChange: (value?: string) => void;
    setValue: (value: string) => void;
  };
  icon?: IconName | IconProps;
  disable?: boolean;
  dataTestId?: string;
  placeholder?: string;
}>;

export const MaskUrl = styled.div`
  width: 148px;
  height: 43px;

  @media (max-width: ${breakpoints[0]}px) {
    width: 123px;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  background: #f7fafc;
  border: 1px solid ${(p) => p.theme.colors.neutral[400]};
  box-sizing: border-box;

  font-size: 14px;
  color: ${(p) => p.theme.colors.primary[500]};

  &:first-of-type {
    width: 76px;
    border-right: none;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;

    @media (max-width: ${breakpoints[0]}px) {
      width: 60px;
    }
  }

  &:last-of-type {
    border-left: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
`;

export const InputUrl: React.FC<PropsWithRef<PropsInputURL>> = ({
  label,
  style,
  url,
  icon,
  disable,
  dataTestId,
  placeholder,
}) => {
  const formatUrl = (url: string) =>
    url
      .replace("https://", "")
      .replace(".service-now.com", "")
      .replace("/", "");

  const id = `input.${label}.text`;

  return (
    <Wrapper className="form__input">
      {label && (
        <label htmlFor={id}>
          <Text weight={600}>{label}</Text>
        </label>
      )}

      <div style={{ display: "flex" }}>
        <MaskUrl>https://</MaskUrl>

        <div style={{ flex: 1 }}>
          <Input
            id={id}
            style={style}
            {...url}
            disableAutoComplete
            value={formatUrl(url?.value || "")}
            ariaLabel="url"
            icon={icon}
            disabled={disable}
            dataTestId={dataTestId}
            placeholder={placeholder}
          />
        </div>

        <MaskUrl>.service-now.com</MaskUrl>
      </div>
    </Wrapper>
  );
};
