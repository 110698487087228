import React, { useState, useEffect } from "react";
import invariant from "ts-invariant";

import { theme } from "@styles/theme";
import Icon from "@icons/Icon";

import useClickOutsideElement from "@hooks/useClickOutsideElement";
import usePrevious from "@hooks/usePrevious";
import MultiOptions from "./MultiOptions";
import { SelectProps, FilteredOptions, Option } from "./types";

import { Input } from "../Input";
import { ErrorBox, SelectButton, SelectButtonLink, Options } from "../styles";

import { Flex, Box } from "../../ResponsiveBox";
import { H4 } from "../../Headings";

export const Select: React.FC<SelectProps> & {
  MultiOptions: typeof MultiOptions;
} = ({
  options,
  icon,
  placeholder,
  optionsMaxHeight = 130,
  onChange,
  ariaLabel,
  label,
  value,
  errors = [],
  onValueChange,
  defaultValue,
  small = false,
  onCloseOptions,
  disabled,
  link = false,
}) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [preventToggle, setPreventToggle] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<FilteredOptions[]>([]);

  const selectRef = React.createRef<HTMLDivElement>();
  const inputRef = React.createRef<HTMLInputElement>();
  const selectButtonRef = React.createRef<HTMLButtonElement>();

  const updateOptions = () => {
    setFilteredOptions(
      options
        ?.filter((option) => !option.hidden)
        .map((option) =>
          option.value === selectedOption
            ? { ...option, selected: true }
            : { ...option, selected: false },
        ),
    );
  };

  const previousSelectedOption = usePrevious(selectedOption);

  useEffect(() => {
    if (defaultValue) {
      const isValidDefaultValue = options.find(
        ({ value }) => value === defaultValue,
      );
      invariant(
        isValidDefaultValue,
        "The default value must be a value inside options",
      );

      setSelectedOption(defaultValue);
    }
  }, []);

  useEffect(() => setSelectedOption(value || ""), [value]);
  useEffect(() => updateOptions(), [options]); // to force front update when options change

  useEffect(() => {
    updateOptions();

    if (!selectedOption && previousSelectedOption && onChange) onChange("");

    if (selectedOption) {
      const option = options?.find(({ value }) => value === selectedOption);
      invariant(option, "Invalid option passed to Select component");
      if (!option) return;

      onValueChange?.(option);
      onChange?.(selectedOption);
      setShowOptions(false);
      setFilterText(option.label);
    }
  }, [selectedOption]);

  useEffect(() => {
    setFilteredOptions(
      options
        ?.filter((option) => !option.hidden)
        ?.filter((option) =>
          option.label?.toLowerCase().includes(filterText.toLowerCase()),
        )
        .map((option) =>
          option.value === selectedOption
            ? { ...option, selected: true }
            : { ...option, selected: false },
        ),
    );
  }, [filterText]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // keyCode 32 = Space
      // Whenever you hit the spacebar and a button is on focus..
      // ..the on click will be triggered, so we prevent this right here
      if (event.keyCode === 32) {
        setPreventToggle(true);
      } else {
        setPreventToggle(false);
      }
    };

    if (showOptions) {
      inputRef.current?.addEventListener("keydown", handleKeyDown);
    } else {
      const opt = options.find(({ value }) => value === selectedOption);
      onCloseOptions?.(opt);
    }

    return () => {
      inputRef.current?.removeEventListener("keydown", handleKeyDown);
    };
  }, [showOptions]);

  const handleClickOutsideSelect = () => {
    if (selectedOption) {
      const currentSelectedOption = options.find(
        ({ value }) => value === selectedOption,
      );

      const label = currentSelectedOption?.label;

      if (!filterText) {
        setFilterText("");
        setSelectedOption("");
      } else if (filterText !== label) {
        setFilterText(label || "");
      }
    } else {
      setFilterText("");
    }

    setShowOptions(false);
  };

  useClickOutsideElement(
    {
      ref: selectRef,
      onClickOutside: handleClickOutsideSelect,
    },
    [selectedOption, filterText, selectRef],
  );

  const handleSelectClick = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();

    if (!disabled) {
      if (selectedOption) {
        updateOptions();
      }

      if (!preventToggle) {
        !showOptions ? inputRef.current?.focus() : inputRef.current?.blur();
        setShowOptions(!showOptions);
      }
    }
  };

  const handleSelectLinkClick = (event: React.SyntheticEvent<HTMLElement>) => {
    event.preventDefault();

    if (disabled) {
      return;
    }

    if (selectedOption) updateOptions();
    if (!preventToggle) setShowOptions(!showOptions);
  };

  const handleOptionClick = (
    event: React.SyntheticEvent<HTMLElement>,
    option: Option,
  ): void => {
    event.preventDefault();
    setFilterText(option.label);
    setShowOptions(false);
    setSelectedOption(option.value);
  };

  const style = small ? { style: { height: 27 } } : {};

  return (
    <Box
      ref={selectRef}
      minH={small ? 27 : link ? "none" : 43}
      className="form__input"
      pB={showOptions ? "lg" : "none"}
    >
      {value && onChange && (
        <Input
          style={{ position: "absolute", display: "none" }}
          value={value}
          onChange={onChange}
          ariaLabel={ariaLabel}
          disabled={disabled}
          tabIndex={-1}
        />
      )}

      {!link ? (
        <SelectButton
          ref={selectButtonRef}
          onClick={handleSelectClick}
          small={small || false}
          focus={showOptions}
          error={!!errors?.length}
          tabIndex={-1}
          data-testid="select-button"
        >
          <Input
            {...style}
            disabled={disabled}
            value={filterText}
            onChange={(value) => setFilterText(value)}
            icon={icon}
            placeholder={placeholder}
            ref={inputRef}
            ariaLabel={`${ariaLabel}-select`}
            label={label}
            disableAutoComplete
            dataTestId="select-input"
          />
          <Box
            position="absolute"
            style={{
              right: small ? theme.spacing.sm : theme.spacing.md,
              bottom: "50%",
              transform: "translateY(50%)",
            }}
            maxH={small ? "sm" : "md"}
          >
            <Icon
              name="chevron-down"
              fill={disabled ? "primary.200" : "neutral.500"}
              size={15}
            />
          </Box>
        </SelectButton>
      ) : (
        <SelectButtonLink
          direction="row"
          alignItems="center"
          onClick={handleSelectLinkClick}
        >
          <H4 color="blue" colorWeight="600" mR="xxs">
            {filterText}
          </H4>
          <Icon name="chevron-down" fill="blue.600" size={16} />
        </SelectButtonLink>
      )}

      {!!errors?.length && !showOptions && <ErrorBox>{errors[0]}</ErrorBox>}
      {showOptions && (
        <Options width="100%" maxH={optionsMaxHeight}>
          {filteredOptions?.length ? (
            filteredOptions.map((option) => (
              <button
                onClick={(e) => handleOptionClick(e, option)}
                key={option.value}
              >
                {option.selected && (
                  <Flex
                    position="absolute"
                    height="100%"
                    width="100%"
                    alignItems="center"
                    justify="center"
                    maxW={48}
                  >
                    <Icon name="check" fill="blue.500" size={14} />
                  </Flex>
                )}
                {option.label}
              </button>
            ))
          ) : (
            <span>No results found</span>
          )}
        </Options>
      )}
    </Box>
  );
};

Select.MultiOptions = MultiOptions;
