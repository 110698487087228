import React, { useState, useCallback, useEffect } from "react";

import * as yup from "yup";
import * as TE from "fp-ts/lib/TaskEither";
import { pipe } from "fp-ts/function";

export type Status =
  | "IDLE"
  | "INVALID"
  | "VALID"
  | "LOADING"
  | "ERROR"
  | "DONE";

export const useForm = function <
  Fields extends Record<string, any>,
  Result,
  Errors = { [K in keyof Fields]?: string[] },
>(
  initialValue: Fields,
  schema?: yup.ObjectSchema,
  submit?: (f: Fields) => TE.TaskEither<any, Result>,
) {
  type FormFields = keyof Fields;
  const [status, setStatus] = useState<Status>("IDLE");
  const [form, setForm] = useState<Fields>(initialValue);
  const [result, setResult] = useState<Result>(null!);
  const [errors, setErrors] = useState<Errors & { formErrors: string[] }>(
    Object.keys(initialValue).reduce((o, v) => ({ ...o, [v]: [] }), {
      formErrors: [],
    } as any),
  );

  useEffect(() => {
    setForm(initialValue);
  }, [JSON.stringify(initialValue)]);

  const setField =
    <Name extends keyof Fields>(name: Name) =>
    (value: Fields[Name]) => {
      const updatedForm = { ...form, [name]: value };

      if (schema) {
        try {
          schema.validateSyncAt(name as string, updatedForm);
          setErrors({ ...errors, [name]: [] });
        } catch (error) {
          if (error instanceof yup.ValidationError) {
            setErrors({ ...errors, [name]: error.errors });
          }
        }
      }

      setForm(updatedForm);
    };

  const useField = React.useCallback(
    (name: keyof Fields & keyof Errors) => ({
      value: form[name] || "",
      errors: errors[name],
      onChange: setField(name),
      setValue: (newValue: string) => {
        // @ts-ignore
        if (form && name in form) {
          form[name] = newValue as any;
        }
      },
    }),
    [form, errors, setField],
  );

  useEffect(() => {
    try {
      schema?.validateSync(form);
      setStatus("VALID");
    } catch (error) {
      setStatus("INVALID");
    }
  }, [form, setField]);

  useEffect(() => {
    if (status === "LOADING" && submit) {
      pipe(
        submit(form),
        TE.bimap(
          (e) => {
            setStatus("ERROR");
            setErrors(e);
          },
          (r) => {
            setStatus("DONE");
            setResult(r);
          },
        ),
      )();
    }
  }, [status]);

  const handleSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
    (e) => {
      e?.preventDefault();
      if (status === "VALID") setStatus("LOADING");
    },
    [status],
  );

  // [keyof Fields]
  const reset = (names?: FormFields[]) => {
    const array = names?.length && names.length > 0 ? names : Object.keys(form);

    const updatedFields = array.reduce(
      (acc, name) => ({ ...acc, [name]: "" }),
      form,
    );

    const updatedErrors = array.reduce(
      (acc, name) => ({ ...acc, [name]: [] }),
      errors,
    );

    setForm(updatedFields);
    setErrors(updatedErrors);
  };

  return {
    useField,
    status,
    handleSubmit,
    formErrors: errors.formErrors || [],
    reset,
    result,
  };
};
