import React, { useState, useEffect } from "react";
import pluralize from "pluralize";

import { theme } from "@styles/theme";
import Icon from "@icons/Icon";

import useClickOutsideElement from "@hooks/useClickOutsideElement";
import usePrevious from "@hooks/usePrevious";
import { MultiOptionsProps, FilteredOptions } from "./types";

import { Box, Flex } from "../../ResponsiveBox";
import { Input } from "../Input";
import {
  ErrorBox,
  SelectButton,
  Options,
  Checkbox,
  OptionButton,
} from "../styles";

const MultiOptions: React.FC<MultiOptionsProps> = ({
  options: defaultOptions,
  icon,
  placeholder,
  optionsMaxHeight = 130,
  ariaLabel,
  label,
  errors = [],
  onValueChange,
  defaultValue,
  selectAllValue,
  small,
  onCloseOptions,
  name,
  disabled,
}) => {
  const [options, setOptions] = useState<FilteredOptions[]>([]);
  const [filteredOptions, setFilteredOptions] = useState<FilteredOptions[]>([]);
  const [filterText, setFilterText] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [inputPlaceholder, setInputPlaceholder] = useState(placeholder);

  const selectRef = React.createRef<HTMLDivElement>();
  const inputRef = React.createRef<HTMLInputElement>();
  const selectButtonRef = React.createRef<HTMLButtonElement>();

  const selectAllOption = defaultOptions.find(
    ({ value }) => value === selectAllValue,
  );

  useEffect(() => {
    if (showOptions) {
      setFilterText("");
    }

    if (!showOptions && onCloseOptions && options.length) {
      onCloseOptions(options);
    }

    setInputPlaceholder(filterText || placeholder);
  }, [showOptions]);

  const previousOptions = usePrevious(options);

  useEffect(() => {
    if (!previousOptions?.length) {
      handleFilterText();
    }
    if (options.length) {
      if (onValueChange) onValueChange(options);
    }
  }, [options]);

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) =>
        option.label.toLowerCase().includes(filterText.toLowerCase()),
      ),
    );
  }, [options, filterText]);

  useEffect(() => {
    if (defaultValue === selectAllValue) {
      setFilterText(selectAllOption?.label || "");
      setOptions(
        defaultOptions
          .filter((option) => !option.hidden)
          .map((option) => ({
            ...option,
            selected: true,
          })),
      );
    } else {
      setOptions(
        defaultOptions
          .filter((option) => !option.hidden)
          .map((option) => ({
            ...option,
            selected: option.selected || defaultValue === option.value,
          })),
      );
    }
  }, []);

  const handleFilterText = () => {
    const totalSelectedOptions = options.reduce(
      (total, { selected, value }) =>
        value !== selectAllValue && selected ? total + 1 : total,
      0,
    );

    const isAllOptionsSelected = checkAllOptionsSelected(options);

    if (isAllOptionsSelected) {
      setFilterText(selectAllOption?.label || "");
    } else if (totalSelectedOptions === 1) {
      setFilterText(options?.find(({ selected }) => selected)?.label || "");
    } else if (totalSelectedOptions > 1) {
      const option = pluralize(name || "option", totalSelectedOptions, true);
      setFilterText(option);
    } else {
      setFilterText("");
    }
  };

  const checkAllOptionsSelected = (checkOptions: FilteredOptions[]): boolean =>
    !checkOptions.some(
      ({ selected, value }) => !selected && value !== selectAllValue,
    );

  const handleClickOutsideSelect = () => {
    handleFilterText();
    setShowOptions(false);
  };

  useClickOutsideElement(
    {
      ref: selectRef,
      onClickOutside: handleClickOutsideSelect,
    },
    [options, filterText, selectRef],
  );

  const handleNewOptions = (selectedOption: { value: string }) => {
    const newOptions = options.map((option) => ({
      ...option,
      selected:
        option.value === selectedOption.value
          ? !option.selected
          : option.selected,
    }));

    const isAllOptionsSelected = checkAllOptionsSelected(newOptions);
    const isAllOptionsUnselected = !newOptions.some(
      ({ selected, value }) => selected && value !== selectAllValue,
    );

    if (isAllOptionsSelected) {
      setOptions(
        options.map((option) => ({
          ...option,
          selected: true,
        })),
      );
    } else if (isAllOptionsUnselected) {
      setOptions(
        options.map((option) => ({
          ...option,
          selected: false,
        })),
      );
    } else {
      const hasSomeUnselectedOption = newOptions.some(
        ({ selected }) => !selected,
      );

      setOptions(
        hasSomeUnselectedOption
          ? newOptions.map((option) =>
              option.value === selectAllValue
                ? { ...option, selected: false }
                : option,
            )
          : newOptions,
      );
    }
  };

  const handleOptionClick = (
    e: React.SyntheticEvent<HTMLElement>,
    selectedOption: FilteredOptions,
  ) => {
    e.preventDefault();
    if (selectedOption.value === selectAllValue) {
      setOptions(
        options.map((option) => ({
          ...option,
          selected: !selectedOption?.selected,
        })),
      );
    } else {
      handleNewOptions(selectedOption);
    }
  };

  const style = small ? { style: { height: 27 } } : {};

  const toggleOptions = () => {
    if (!disabled) {
      setShowOptions(!showOptions);
      handleFilterText();
      if (showOptions) {
        inputRef.current?.blur();
      }
    }
  };

  return (
    <Box ref={selectRef} minH={small ? 27 : 43} className="form__input">
      <SelectButton
        ref={selectButtonRef}
        type="button"
        onClick={toggleOptions}
        small={small || false}
        focus={showOptions}
        error={!!errors?.length && !showOptions}
      >
        <Input
          {...style}
          disabled={disabled}
          value={filterText}
          onChange={(value) => setFilterText(value)}
          icon={icon}
          placeholder={inputPlaceholder}
          ref={inputRef}
          label={label}
          ariaLabel={`${ariaLabel}-select`}
          error={!!errors?.length && !showOptions}
          disableAutoComplete
        />
        <Box
          position="absolute"
          style={{
            right: small ? theme.spacing.sm : theme.spacing.md,
            bottom: "50%",
            transform: "translateY(50%)",
          }}
          maxH={small ? "sm" : "md"}
        >
          <Icon name="chevron-down" fill="neutral.500" size={15} />
        </Box>
      </SelectButton>
      {!!errors?.length && !showOptions && <ErrorBox>{errors[0]}</ErrorBox>}
      {showOptions && (
        <Options width="100%" maxH={optionsMaxHeight} position="absolute">
          {filteredOptions?.length ? (
            filteredOptions.map(
              (option) =>
                !option.hidden && (
                  <OptionButton
                    onClick={(e) => handleOptionClick(e, option)}
                    key={option.value}
                  >
                    <Flex
                      position="absolute"
                      height="100%"
                      width="100%"
                      alignItems="center"
                      justify="center"
                      maxW={48}
                    >
                      <Checkbox checked={option.selected || false}>
                        {option.selected && (
                          <Icon name="check" fill="neutral.0" size={12} />
                        )}
                      </Checkbox>
                    </Flex>
                    {option.label}
                  </OptionButton>
                ),
            )
          ) : (
            <span>No results found</span>
          )}
        </Options>
      )}
    </Box>
  );
};

export default MultiOptions;
