import { CSSProperties } from "react";

import {
  dynamicMargin,
  dynamicPadding,
  MarginProps,
  ColorProps,
  dynamicColor,
  PaddingProps,
  CursorProps,
} from "../../styles/helpers";
import { Theme, styled } from "../../styles/theme";

export type Props = PaddingProps &
  CursorProps &
  MarginProps &
  ColorProps & {
    muted?: boolean;
    color?: keyof Theme["colors"];
    weight?: CSSProperties["fontWeight"] | number;
    align?: "left" | "right" | "center" | "justify";
  };

export const H1 = styled.h1<Props>`
  ${dynamicMargin};
  ${dynamicPadding};
  ${dynamicColor};

  font-size: 30px;
  font-weight: ${(p) => p.weight || "600"};
  font-family: ${(p) => p.theme.fonts.main};
  text-align: ${(p) => p.align || "inherit"};
  cursor: ${(p) => p.cursor};
`;

export const H2 = styled.h2<Props>`
  ${dynamicMargin};
  ${dynamicPadding};
  ${dynamicColor};

  font-size: 24px;
  font-weight: ${(p) => p.weight || "600"};
  font-family: ${(p) => p.theme.fonts.main};
  text-align: ${(p) => p.align || "inherit"};
  cursor: ${(p) => p.cursor};
`;

export const H3 = styled.h3<Props>`
  ${dynamicMargin};
  ${dynamicPadding};
  ${dynamicColor};

  font-size: 20px;
  font-weight: ${(p) => p.weight || "600"};
  font-family: ${(p) => p.theme.fonts.main};
  text-align: ${(p) => p.align || "inherit"};
  cursor: ${(p) => p.cursor};
`;

export const H4 = styled.h4<Props>`
  ${dynamicMargin};
  ${dynamicPadding};
  ${dynamicColor};

  font-size: 16px;
  font-weight: ${(p) => p.weight || "600"};
  font-family: ${(p) => p.theme.fonts.main};
  text-align: ${(p) => p.align || "inherit"};
  cursor: ${(p) => p.cursor};
`;

export const H5 = styled.h5<Props>`
  ${dynamicMargin};
  ${dynamicPadding};
  ${dynamicColor};

  font-size: 14px;
  font-weight: ${(p) => p.weight || "600"};
  font-family: ${(p) => p.theme.fonts.main};
  text-align: ${(p) => p.align || "inherit"};
  cursor: ${(p) => p.cursor};
`;
