import React, { useState, useEffect, useCallback } from "react";
import invariant from "ts-invariant";
import * as yup from "yup";

import usePrevious from "@hooks/usePrevious";
import { Input } from "./Input";
import { Text } from "../Text";
import {
  TagsWrapper,
  MailTag,
  ErrorBox,
  RemoveButton,
  MailTagsWrapper,
} from "./styles";

import { Spacer } from "../Spacer";
import Icon from "../../../icons/Icon";
import { Box } from "../ResponsiveBox";

export interface ITag {
  name: string;
  error?: boolean;
}

export interface Props {
  ariaLabel?: string;
  label?: string;
  placeholder?: string;
  errors?: string[];
  onChangeTags: (tags: ITag[]) => void;
  onChangeValue?: (tags: ITag[]) => void;
  disabled?: boolean;
  initialEmails?: ITag[];
  inputType?: "email" | "id";
}

const AnyTagsSchema = yup.object().shape({
  tags: yup.array().of(yup.string().required()),
});

const MailTagsSchema = yup.object().shape({
  tags: yup.array().of(yup.string().email().required()),
});

const MailTags: React.FC<Props> = ({
  ariaLabel,
  label,
  placeholder,
  errors = [],
  onChangeTags,
  onChangeValue,
  disabled = false,
  initialEmails = [],
  inputType = "email",
}) => {
  const [value, setValue] = useState("");
  const [tags, setTags] = useState<ITag[]>(initialEmails);
  const [error, setError] = useState("");
  const [tagsWrapperHeight, setTagsWrapperHeight] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);

  const tagsRef = React.createRef<HTMLDivElement>();

  invariant(label || ariaLabel, "Must provide aria-label or label");

  const hasDuplication = useCallback(
    () => tags.some((email) => email.name === value),
    [tags, value],
  );

  useEffect(() => {
    if (initialEmails.length > 0) setTags(initialEmails);
  }, [initialEmails]);

  const addTag = async (): Promise<void> => {
    if (hasDuplication()) {
      setError(
        inputType === "email"
          ? "Email already added."
          : "This ID has already been added",
      );
    } else {
      const valid =
        inputType === "email"
          ? await MailTagsSchema.isValid({
              tags: [...tags.map(({ name }) => name), value],
            })
          : await AnyTagsSchema.isValid({
              tags: [...tags.map(({ name }) => name), value],
            });

      if (valid) {
        const newTags = [...tags, { name: value }];
        setTags(newTags);
        onChangeTags(newTags);
        onChangeValue?.(newTags);
        setValue("");
        setScrollToBottom(true);
      } else {
        setError(
          inputType === "email"
            ? "Email must be valid."
            : "Id must have a value.",
        );
      }
    }
  };

  useEffect(() => {
    setError("");

    const handleKeyDown = (event: KeyboardEvent) => {
      if (isFocused) {
        if (
          event.key === "Enter" ||
          event.key === " " ||
          (event.key === "Tab" && value !== "")
        ) {
          event.preventDefault();
          addTag();
        }

        if (value === "" && event.key === "Backspace" && tags.length > 0) {
          const lastEmail = tags[tags.length - 1];
          removeTag(lastEmail.name);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [value, isFocused, tags]);

  useEffect(() => {
    if (tagsRef.current) {
      setTagsWrapperHeight(tagsRef.current.clientHeight);
    }
  }, [tagsRef]);

  useEffect(() => {
    if (scrollToBottom) {
      tagsRef.current?.scrollTo({ top: tagsRef.current.scrollHeight + 35 });
      setScrollToBottom(false);
    }
  }, [scrollToBottom]);

  const removeTag = (tag: string): void => {
    const newTags = tags.filter((t) => t.name !== tag);
    onChangeValue?.(newTags);
    onChangeTags(newTags);
    setTags(newTags);
  };

  const prevIsFocused = usePrevious(isFocused);

  useEffect(() => {
    if (prevIsFocused !== null && !isFocused && value) {
      addTag();
    }
  }, [isFocused]);

  return (
    <MailTagsWrapper hasTags={!!tags.length}>
      <Box
        position="absolute"
        pL="md"
        pR="xs"
        pT="xs"
        style={{ marginTop: label ? 27.5 : 0 }}
      >
        <TagsWrapper
          pR="xxs"
          direction="row"
          ref={tagsRef}
          wrap="wrap"
          overflow="auto"
          maxH={148}
        >
          {tags.map((email) => (
            <MailTag
              bg={email.error ? "red.200" : "neutral.200"}
              key={email.name}
              pY="xxs"
              pR="lg"
              pL="xs"
              mR="xs"
              mB="xs"
              justify="center"
              alignItems="center"
            >
              <Text
                color={email.error ? "red" : "primary"}
                colorWeight={email.error ? "800" : "500"}
              >
                {email.name}
              </Text>

              <RemoveButton
                onClick={() => removeTag(email.name)}
                disabled={disabled}
              >
                <Icon
                  name="close"
                  fill={email.error ? "red.600" : "primary.100"}
                  size={16}
                />
              </RemoveButton>
            </MailTag>
          ))}
        </TagsWrapper>
      </Box>

      <Input
        disableAutoComplete
        disabled={disabled}
        ariaLabel={ariaLabel}
        label={label}
        placeholder={placeholder || "Emails"}
        error={!!error || !!errors.length}
        value={value}
        onChange={setValue}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        style={{ paddingTop: tags.length ? tagsWrapperHeight + 6 : 0 }}
      />

      {(errors.length || error) && (
        <>
          <Spacer size="xs" />
          <ErrorBox style={{ marginBottom: 0 }}>{error || errors[0]}</ErrorBox>
        </>
      )}
    </MailTagsWrapper>
  );
};

export default MailTags;
